import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Error from "../../component/notification/error";
import Notification from "../../component/notification/notification";
import { Container, inputAdornmentClasses } from "@mui/material";
import Header from "../../component/common/header";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    setError("");

    const emailInput = document.getElementById("email");
    // バリデーションが通るかどうかをチェック
    if (!emailInput.checkValidity()) {
      setError(emailInput.validationMessage);  // ブラウザのバリデーションメッセージを取得
    }else {
      try {
        const response = await axios.post(`${apiUrl}/api/authentication/forget-password/`, { email });
  
        if (response.status === 201) {
          setMessage("認証リンクが送信されました。");
          navigate("/wait-email")
        } else {
          setMessage("Resend verification link.");
        }
      } catch (error) {
        if (error.response && error.response.data) {
          setError(error.response.data.error || "An unexpected error occurred.");
        } else {
          setError("An error occurred while making the request.");
        }
      }
    }
  };

  return (
    <div className="h-screen overflow-hidden">
      <Header />
      <Container className="h-full flex flex-col justify-center">
        <div
          className="h-full flex flex-col flex-grow bg-bottom bg-no-repeat bg-contain"
        >
          <div className="flex flex-col items-center justify-center flex-grow px-10 pb-20">
            <div className="w-full max-w-md bg-white rounded-lg p-8">
              <form className="w-full" onSubmit={handleSubmit} noValidate>
                <div className="mb-10">
                  <h1 className="text-2xl font-bold text-center text-gray-900 mb-3">
                    パスワード再設定
                  </h1>
                </div>
                <div className="">
                  <label htmlFor="" className="text-blue-700 text-lg font-bold">メールアドレスを入力してください。</label>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onInvalid={(e) => e.preventDefault()}  // デフォルトのバリデーションメッセージを無効化
                    className="w-full px-4 py-3 border-b-2 border-gray-200 focus:outline-none focus:border-gray-500"
                    autoComplete="off"
                    required
                    style={{
                      width: '100%',
                      padding: '10px 16px',
                      boxSizing: 'border-box',
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      marginTop:"3px",
                      marginBottom: "10px"
                    }}
                  />
                </div>
                <div className="flex justify-between items-center mb-6">
                  <label
                    htmlFor="remember_me"
                    className="flex items-center text-sm font-semibold text-gray-700"
                  >
                    <span className="px-2.5">
                      登録されているメールアドレス宛にパスワード再設定用のURLをお送りいたします。
                    </span>
                  </label>
                </div>
                <button
                  type="submit"
                  className="w-full py-3 bg-[#2467C1] text-white font-bold rounded-full hover:bg-blue-800 focus:outline-none focus:bg-[#0e1225]"
                >
                  <span className="inline-block mr-2">送信</span>
                  <span className="inline-block spinner-border spinner-border-sm align-middle"></span>
                </button>
                <div className="flex justify-center mt-4 text-gray-700 font-semibold text-sm">
                  <div
                    onClick={() => navigate("/login")}
                    className="text-blue-500 font-semibold cursor-pointer"
                  >
                    戻る
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        { error && (<Error content={error} />) }
        { message && (<Notification content={message} />) }
      </Container>
    </div>
  );
};

export default ForgotPassword;
