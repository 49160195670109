import React, { useContext } from 'react';
import { UserContext } from '../context/UserContext';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, allowedRoles }) => {
  const { user, loading } = useContext(UserContext);



  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/login" />;
  }
  console.log(allowedRoles && !allowedRoles.includes(user.is_admin));
  if (allowedRoles && !allowedRoles.includes(user.is_admin)) {

    return <div>You do not have access to this page.</div>;
  }


  return <Component />;
};

export default ProtectedRoute;
