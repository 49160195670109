import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { gapi } from 'gapi-script';

const AudioPlayer = ({ handleClose, voiceUrl, setError }) => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const [audio, setAudio] = useState();
  const [loading, setLoading] = useState(true);
  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    // アクセストークンをバックエンドから取得
    const fetchToken = async () => {
      setLoading(true);  // 音声ファイルの読み込み開始時にローディング開始
      try {
        const response = await fetch(`${apiUrl}/api/call_result/driveToken/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        if (data.access_token) {
          setAccessToken(data.access_token);
        } else {
          setError('アクセストークンを取得できませんでした。');
        }
      } catch (error) {
        console.error("Error fetching token:", error);
        setError('アクセストークンの取得に失敗しました。');
        setLoading(false);  // エラー発生時もローディングを終了
      }
    };
    fetchToken();
  }, [setError]);
  
  useEffect (() => {
    setError('');
    if(accessToken && voiceUrl) {
      const fetchAudio = async () => {
        const fileId = voiceUrl.split("/d/")[1].split("/")[0].split("?")[0];
        const directAudioUrl = `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`;

        try {
          const response = await fetch(directAudioUrl, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
          setLoading(false);  // エラー発生時もローディングを終了

          const blob = await response.blob();
          const audioUrl = URL.createObjectURL(blob);
          setAudio(audioUrl);
        } catch (error) {
          console.error("Error fetching audio file: ", error);
          setError('音声ファイルを取得できませんでした。');
          setLoading(false);  // エラー発生時もローディングを終了
        }
      };

      fetchAudio();
    }
  }, [accessToken, voiceUrl, setError]);

  return (
    <div className='h-32'>
      <button onClick={handleClose} className="absolute top-1 right-4 text-gray-600 text-3xl">
        &times;
      </button>
      <div className='h-full flex justify-center items-center'>
        {loading ? (
            <div>読み込み中...</div>
          ) : (audio && (
            <audio
              controls
              src={audio}
              controlsList="nodownload" // ダウンロードを無効化
            />
          )
        )}
      </div>
    </div>
  );
};

export default AudioPlayer;