import React from "react";
import Container from "../../component/dashboard/Container";
import ResultTable from "../../component/dashboard/ResultTable";

const ResultPage = () => {
    return (
        <Container className="bg-[#f8f8fb] h-full flex-1">
            <ResultTable/>
        </Container>
    );
}
export default ResultPage;