import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Error from "../../component/notification/error";
import Notification from "../../component/notification/notification";
import Header from "../../component/common/header";
import { Container } from "@mui/material";
import axios from "axios";

const MailVerify = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const routerLocation = useLocation();
  const [error, setError] = useState("");
  const [content, setContent] = useState("");

  useEffect(() => {
    const params = new URLSearchParams(routerLocation.search);
    const user_id = params.get("id");

    axios
      .put(`${apiUrl}/api/authentication/mail-verify/`, {
        user_id: user_id
      })
      .then((response) => {
        setContent(response.data["success"]);
      })
      .catch((error) => {
        setError(error.data["error"]);
      })
  }, []);

  return (
    <div className="h-screen overflow-hidden">
      <Header />
      <Container className="h-full flex flex-col justify-center">
        <div className="h-full mx-16 px-16 pb-24">
          <div className="h-full max-w-[680px] flex flex-col items-center justify-center flex-grow mx-24 p-24">
            <h2 className="mb-3 text-2xl font-bold text-center">メールアドレスの認証</h2>
            <div className="my-8 text-center">
              <div className="mb-3 text-xl">メールアドレスの認証が完了しました。</div>
              <div className="text-gray-700">管理者から電話番号の登録が完了するまでログインはできません。<br />また、登録されるまでに時間がかかる場合があります。ご了承ください。</div>
            </div>
            <a href="/login" className="text-sm text-blue-500 font-bold underline">
              ログインはこちら
            </a>
          </div>
        </div>
        <Error content={error} />
        <Notification content={content} />
      </Container>
    </div>
  );
};

export default MailVerify;
