import Typography from '@mui/material/Typography';

function GridBox({ headerText, contentText, sub_text, contentFontSize }) {
  return (
    <div className=" flex flex-col flex-auto shadow rounded-2xl overflow-hidden text-center">
      <div className="bg-white h-full p-4">
        <p className="text-lg font-semibold">{headerText}</p>
        <Typography className=" text-blue-600 pt-5 text-2xl font-bold tracking-tight leading-non" fontSize={contentFontSize} fontWeight={700}>
          {contentText}
        </Typography>
        <p className=" text-base font-medium">{sub_text}</p>
      </div>
    </div>
  );
}

export default GridBox;