import React, { useEffect, useState } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import axios from 'axios';

const SelectUser = ({ onChange, user }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [users, setUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState('0');
  const [error, setError] = useState(null); // エラーハンドリング用

  useEffect(() => {
    const token = localStorage.getItem('token');
    axios.get(`${apiUrl}/api/authentication/getUserName/`, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(response => {
      setUsers(response.data);
    })
    .catch((error) => {
      console.log("Error: ", error);
      localStorage.removeItem('token');
      setError("ユーザー情報の取得に失敗しました。")
    })
  }, [apiUrl]);

  const handleChange = (event) => {
    const userid = event.target.value;
    setSelectedUserId(userid);
    onChange(userid); // 親コンポーネントに通知
  };

  return (
    <div className=' flex flex-col items-start p-4 pl-0'>
      <label htmlFor="callType" className="font-semibold mb-2">利用者</label>
      <FormControl sx={{width: 300}}>
        <Select
          value={selectedUserId}
          onChange={handleChange}
          input={<OutlinedInput />}
          renderValue={(selected) => {
            const selectedUser = users.find(user => user.id === selected);
            return (
              <Typography className={`${user==='1'? (selectedUser ? '' : 'text-[#909090]') : ''}`}>
                {selectedUser ? selectedUser.username
                  : (user==='0' ? 'すべて' : '利用者を選択してください')
                }
              </Typography>
            );
          }}
        >
          { user=='0' ? <MenuItem value='0'>すべて</MenuItem> : null }
          {users.map((data) => (
            <MenuItem
              key={data.id}
              value={data.id}
            >
              {data.username}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default SelectUser;
