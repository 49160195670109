export const downloadCSV = (data) => {
    const csvRows = [];
    // Get the headers
    const headers = [
        '電話番号', 'コンタクト', '電話可否', '就業有無', '転職可否', '面談可否', '希望月日', '希望時間', '通話種別', '通話時刻', '音声'
    ];
    csvRows.push(headers.join(','));

    // Format the data
    for (const row of data) {
        const values = [
            row.phone_number,
            row.contact,
            row.phone_availability,
            row.working_existence,
            row.job_change_availability,
            row.interview_availability,
            row.interview_desired_md,
            row.interview_desired_time,
            row.call_type,
            row.call_time,
            row.voice_url ? 'あり' : 'なし'
        ];
        csvRows.push(values.join(','));
    }

    // Create a Blob from the CSV string and trigger download
    const csvString = csvRows.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'data.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
