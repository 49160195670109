import React from 'react';
import '../../assets/css/scrollcustom.css';

const Container = ({ children, className, style }) => {
    return (
        <div
            className={`max-h-[calc(100vh-60px)] w-screen overflow-auto mx-auto py-10 xl:px-20 md:px-14 sm:px-10 px-10 ${className}`}
            style={style}
        >
            {children}
        </div>
    );
};

export default Container;
