import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, IconButton,
  TablePagination
} from '@mui/material';
import PropTypes from 'prop-types';
import { Modal as BaseModal } from '@mui/base/Modal';
import Fade from '@mui/material/Fade';
import { styled } from '@mui/system';
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdOutlineEdit } from "react-icons/md";
import AddUserModal from './AddUserModal';
import Notification from '../notification/notification';
import Error from '../notification/error';

const columns = [
  { id: 'name', label: <strong>利用者名</strong>, minWidth: 230 },
  // { id: 'type', label: '種別', minWidth: 100 },
  {
    id: 'email',
    label: <strong>Email</strong>,
    minWidth: 150,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'contact_name',
    label: <strong>担当者名</strong>,
    minWidth: 150,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'authority',
    label: <strong>権限</strong>,
    minWidth: 150,
    format: (value) => value.toFixed(2),
  },
];

const UserTable = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isActive, setIsActive] = useState(true);
  const [open, setOpen] = useState(false);
  const [editFlag, setEditFlag] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [notification, setNotification] = useState("");
  const [error, setError] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const editUserInfo = (user) => {
    setSelectedUser(user);
    setEditFlag(true);
    setOpen(true);
    reset();
  }

  const newUserInfo = () => {
    setSelectedUser(null);
    setEditFlag(false);
    setOpen(true);
    reset();
  }

  const reset = () => {
    setError('');
    setNotification('');
  }

  const deleteUser = (userInfo) => {
    const token = localStorage.getItem('token');
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
      params: {
        user_id: userInfo.user_id,
      }
    };

    // user_informationテーブル
    axios.delete(`/api/users/${userInfo.user_id}`, config)
      .then(response => {
        setUsers(prevUsers => prevUsers.filter(user => user.user_id !== userInfo.user_id));
        setNotification("正常に削除されました。");
      })
      .catch(error => {
        console.error("Error: ", error);
        setError("利用者情報の削除に失敗しました。");
    });

    // phone_numberテーブル
    if(userInfo.ai_call_phone_number) {
      axios.delete(`/api/phone_number/delete/`, config)
        .then(response => {
          if (response.status === 200) {
            setNotification("正常に削除されました。");
          } else {
            if(error.response && error.response.data && error.response.data.error) {
              setError(error.response.data.error);
            }else {
              setError('電話番号の削除中に予期せぬエラーが発生しました。');
            }
          }
        }
      )
      .catch(error => {
        console.error("Error: ", error);
        setError("電話番号の削除に失敗しました。", error);
      });
    }
    reset();
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    };
    axios.get('/api/users', config)
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          setUsers(response.data);
        }
      })
      .catch(error => {
        console.error('Error submitting form:', error);
        setUsers([]);
      });
  }, [open]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setPage(0);
  };

  const filteredUsers = users.filter(user => {
    const query = searchQuery.toLowerCase();
    return (
      user.user_name.toLowerCase().includes(query) ||
      // user.type_code.toLowerCase().includes(query) ||
      user.email_address.toLowerCase().includes(query) ||
      user.responsible_person_name?.toLowerCase().includes(query) ||
      (user.is_admin ? '管理者' : '利用者').toLowerCase().includes(query)
    );
  });

  return (
    <div className='text-lg'>
      <div className=' flex justify-between mt-5'>
        <div className="flex items-center space-x-2">
          <div className="flex border border-gray-300 rounded-md overflow-hidden">
            <button
              className={`px-4 py-2 ${isActive ? 'bg-gray-200 font-bold' : 'bg-gray-100'}`}
              onClick={() => setIsActive(true)}
            >
              Active
            </button>
            <button
              className={`px-4 py-2 ${!isActive ? 'bg-gray-200 font-bold' : 'bg-gray-100'}`}
              onClick={() => setIsActive(false)}
            >
              Inactive
            </button>
          </div>
          <input
            type="text"
            placeholder="Search"
            onChange={handleSearchChange}
            className="ml-2 px-4 py-2 border border-gray-300 rounded-md"
          />
        </div>
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={newUserInfo}>
            利用者追加
          </Button>
        </div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: StyledBackdrop }}
        >
          <Fade in={open}>
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[50%] bg-white rounded-lg border border-gray-200 shadow-lg p-6">
              <AddUserModal editFlag={editFlag} handleClose={handleClose} user={selectedUser} setNotification={setNotification} setError={setError} />
            </div>
          </Fade>
        </Modal>
      </div>
      <div className=' w-full'>
        <TableContainer className='max-h-[calc(100vh-320px)]'>
          <Table key={filteredUsers.length} stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow sx={{ borderBottom: "2px solid #ddd" }}>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                    sx={{ borderBottom: "none" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {/* {filteredUsers.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((user) => ( */}
              {filteredUsers
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((user, index) => (
                  <TableRow key={user.user_id} sx={{ borderBottom: "1px solid #ddd" }}>
                    <TableCell sx={{ borderBottom: "none", fontSize: "16px" }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {user.profile_image ? (
                          <img
                            src={user.profile_image}
                            alt="User"
                            className="w-[36px] h-[36px] rounded-full object-cover mr-2"
                          />
                        ) : (
                          <div style={{
                            width: 36, height: 36, borderRadius: '50%', backgroundColor: 'gray', display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 8
                          }}>
                            <span style={{ color: 'white' }}>{user.user_name.charAt(0) || "U"}</span>
                          </div>)}
                        {user.user_name || <span className='bg-blue-200 p-1 rounded-sm '>未登録</span>}
                      </div>
                    </TableCell>
                    {/* <TableCell sx={{ borderBottom: "none" }}>{user.type_code || <span className='bg-blue-200 p-1 rounded-sm '>未登録</span>}</TableCell> */}
                    <TableCell sx={{ borderBottom: "none", fontSize: "16px" }}>{user.email_address}</TableCell>
                    <TableCell sx={{ borderBottom: "none", fontSize: "16px" }}>{user.responsible_person_name || <span className='bg-blue-200 p-1 rounded-sm '>未登録</span>}</TableCell>
                    <TableCell sx={{ borderBottom: "none" }}>
                      {user.is_admin === true ? (
                        <span style={{ color: 'white', background: '#E31717', padding: '3px', borderRadius: '2px' }}>
                          管理者
                        </span>
                      ) : (
                        <span style={{ background: '#ECF0F1', padding: '3px', borderRadius: '2px' }}>
                          利用者
                        </span>
                      )}
                    </TableCell>
                    <TableCell sx={{ display: "flex", justifyContent: "flex-end", borderBottom: "none", gap: "10px" }}>
                      <IconButton aria-label="edit" sx={{ borderRadius: 2, backgroundColor: "#EAEFF9", color: "primary.main" }} onClick={() => editUserInfo(user)}>
                        <MdOutlineEdit className='w-4 h-4' />
                      </IconButton>
                      <IconButton color='error' aria-label="delete" sx={{ borderRadius: 2, backgroundColor: "#FCEBEB" }} onClick={() => deleteUser(user)}>
                        <RiDeleteBin6Line className='w-4' />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <CustomPagination
          totalItems={filteredUsers.length}
          currentPage={page}
          itemsPerPage={rowsPerPage}
          onPageChange={handlePageChange}
          className= 'fixed bottom-7 left-1/2 transform -translate-x-1/2'
        /> */}

        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={filteredUsers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Results per page"
          className=' fixed right-16 bottom-5'
        />
      </div>
      {notification && <Notification content={notification} />}
      {error && <Error content={error} />}
    </div>
  );
}

const Backdrop = React.forwardRef((props, ref) => {
  const { open, ...other } = props;
  return (
    <Fade in={open}>
      <div ref={ref} {...other} />
    </Fade>
  );
});

Backdrop.propTypes = {
  open: PropTypes.bool,
};

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;
export default UserTable;
