import React, { useEffect, useState } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import axios from 'axios';

const SelectContact = ({ onChange }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [interviewAvailabilitys, setContacts] = useState([]);
  const [selectContact, setSelectContact] = useState('すべて');
  const [error, setError] = useState(null); // エラーハンドリング用

  useEffect(() => {
    const interviewAvailabilityValue = [
      { id: 0, interview_availability: 'すべて' },
      { id: 1, interview_availability: '〇' },
      { id: 2, interview_availability: '✕' }
    ];
    setContacts(interviewAvailabilityValue);
  }, [apiUrl]);

  const handleChange = (event) => {
    const interviewAvailability = event.target.value;
    setSelectContact(interviewAvailability);
    onChange(interviewAvailability); // 親コンポーネントに通知
  };

  return (
    <>
      <div className='flex flex-col items-start p-4 pl-0'>
        <label htmlFor="callType" className="text-sm font-semibold mb-2">面談可否</label>
        <FormControl sx={{width: 240}}>
          <Select
            value={selectContact}
            onChange={handleChange}
            input={<OutlinedInput />}
            sx= {{
              '& .MuiMenuItem-root': {
                padding: '10px', // メニュー項目のpadding
              },
            }}
            renderValue={(selected) => {
              const selectedInterview = interviewAvailabilitys.find(user => user.interview_availability === selected);
              return (
                <Typography>
                  {selectedInterview ? selectedInterview.interview_availability : 'すべて'}
                </Typography>
              );
            }}
          >
            {interviewAvailabilitys.map((data) => (
              <MenuItem
                key={data.id}
                value={data.interview_availability}
              >
                {data.interview_availability}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <style>
        {`
          .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
            padding: 10px;
          }
        `}
      </style>
    </>
  );
}

export default SelectContact;
