import { TablePagination } from '@mui/material';
import axios from 'axios';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';

const DetailsTable = ({ selected, startDate, endDate, typeCall }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [data, setData] = useState([]);
  const [error, setError] = useState(""); // エラーハンドリング用
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' }); // ソート設定
  const [isInitialLoad, setIsInitialLoad] = useState(true); // 初回ロードかどうかのフラグ

  useEffect(() => {
    const token = localStorage.getItem('token');

    const params = {};
    if(selected) params['user_id'] = selected;
    if(isInitialLoad) { // ログイン直後
      if(startDate && endDate) {
        params['start_date'] = startDate.format('YYYY-MM-DD');
        params['end_date'] = endDate.format('YYYY-MM-DD');
        setIsInitialLoad(false); // フラグをオフにする
      }
    }else {
      if(startDate) params['start_date'] = dayjs(startDate).format('YYYY-MM-DD');
      if(endDate) params['end_date'] = dayjs(endDate).format('YYYY-MM-DD');
    }
    if(typeCall) params['call_type'] = typeCall;

    axios.get(`${apiUrl}/api/call_result/getResultCallTable/`, {
      headers: { Authorization: `Bearer ${token}` },
      params: params
    })
    .then(response => {
      setData(response.data);
    })
    .catch((error) => {
      console.log("Error: ", error);
      localStorage.removeItem('token');
      setError("通話詳細情報の取得に失敗しました。")
    })
  }, [selected, startDate, endDate, typeCall]);

  // 各項目ソート用の関数
  const handleSort = (key) => {
    if (sortConfig.key === key) { // 同じカラムをクリックした場合、ソート順を切り替える
      const newDirection = sortConfig.direction === 'desc' ? 'asc' : sortConfig.direction==='asc' ? null : 'desc';
      setSortConfig({ key, direction: newDirection});
    } else {  // 初めてクリックされた場合、降順
      setSortConfig({ key, direction: 'desc' });
    }
  };
  // ソートされたデータ
  const sortedData = React.useMemo(() => {
    if(!sortConfig.key || !sortConfig.direction) return data; // ソートなし

    return [...data].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }, [data, sortConfig]);
  const filteredData = sortedData ? sortedData.filter(data => {
    const query = searchQuery.toLowerCase();
    return (
      data.date.toLowerCase().includes(query) ||
      data.call_type.toLowerCase().includes(query) ||
      data.contact.toLowerCase().includes(query) ||
      data.phone_availability?.toLowerCase().includes(query) ||
      data.not_phone_availability?.toLowerCase().includes(query) ||
      data.job_change_availability?.toLowerCase().includes(query) ||
      data.not_job_change_availability?.toLowerCase().includes(query) ||
      data.interview_availability?.toLowerCase().includes(query) ||
      data.not_interview_availability?.toLowerCase().includes(query) ||
      data.rate_contact?.toLowerCase().includes(query) ||
      data.rate_interview_outbound?.toLowerCase().includes(query) ||
      data.rate_interview_contact?.toLowerCase().includes(query)
    );
  }): [];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div className="relative bg-white w-full rounded-lg p-5 ">
      <p className="text-[20px] text-center font-semibold">通話詳細</p>
      <div className='p-5 max-h-[500px] overflow-auto'>
        <table className=" bg-white w-full max-h-[430px] overflow-auto">
          <thead>
            <tr className="bg-gray-200">
              <th
                className="px-4 py-2 text-left cursor-pointer"
                onClick={() => handleSort('date')}
              >
                日付 {sortConfig.key === 'date' ?
                      (sortConfig.direction === 'desc' ?
                        '▼' : (sortConfig.direction==='asc' ? '▲' : null))
                      : ''}
              </th>
              <th className="px-4 py-2 text-left cursor-pointer"
                onClick={() => handleSort('call_type')}
              >
                {typeCall==='合計 (架電数 + 受電数)' && '架電数・受電数'}
                {typeCall==='架電数' && '架電数'}
                {typeCall==='受電数' && '受電数'}
                {sortConfig.key === 'call_type' ?
                  (sortConfig.direction === 'desc' ?
                    ' ▼' : (sortConfig.direction==='asc' ? ' ▲' : null))
                  : ''}
              </th>
              <th className="px-4 py-2 text-left cursor-pointer"
                onClick={() => handleSort('contact')}
              >
                コンタクト数 {sortConfig.key === 'contact' ?
                              (sortConfig.direction === 'desc' ?
                                '▼' : (sortConfig.direction==='asc' ? '▲' : null))
                              : ''}
              </th>
              <th className="px-4 py-2 text-left cursor-pointer"
                onClick={() => handleSort('rate_contact')}
              >
                コンタクト率 {sortConfig.key === 'rate_contact' ?
                              (sortConfig.direction === 'desc' ?
                                '▼' : (sortConfig.direction==='asc' ? '▲' : null))
                              : ''}
              </th>
              <th className="px-4 py-2 text-left cursor-pointer"
                onClick={() => handleSort('interview_availability')}
              >
                面談希望数 {sortConfig.key === 'interview_availability' ?
                      (sortConfig.direction === 'desc' ?
                        '▼' : (sortConfig.direction==='asc' ? '▲' : null))
                      : ''}
              </th>
              <th className="px-4 py-2 text-left cursor-pointer"
                onClick={() => handleSort('rate_interview_contact')}
              >
                面談希望率① {sortConfig.key === 'rate_interview_contact' ?
                      (sortConfig.direction === 'desc' ?
                        '▼' : (sortConfig.direction==='asc' ? '▲' : null))
                      : ''}
              </th>
              <th className="px-4 py-2 text-left cursor-pointer"
                onClick={() => handleSort('rate_interview_outbound')}
              >
                面談希望率② {sortConfig.key === 'rate_interview_outbound' ?
                            (sortConfig.direction === 'desc' ?
                              ' ▼' : (sortConfig.direction==='asc' ? ' ▲' : null))
                            : ''}
              </th>
              <th className="px-4 py-2 text-left cursor-pointer"
                onClick={() => handleSort('phone_availability')}
              >
                電話可否〇 {sortConfig.key === 'phone_availability' ?
                      (sortConfig.direction === 'desc' ?
                        '▼' : (sortConfig.direction==='asc' ? '▲' : null))
                      : ''}
              </th>
              <th className="px-4 py-2 text-left cursor-pointer"
                onClick={() => handleSort('not_phone_availability')}
              >
                電話可否✖ {sortConfig.key === 'not_phone_availability' ?
                      (sortConfig.direction === 'desc' ?
                        '▼' : (sortConfig.direction==='asc' ? '▲' : null))
                      : ''}
              </th>
              <th className="px-4 py-2 text-left cursor-pointer"
                onClick={() => handleSort('job_change_availability')}
              >
                転職可否〇 {sortConfig.key === 'job_change_availability' ?
                      (sortConfig.direction === 'desc' ?
                        '▼' : (sortConfig.direction==='asc' ? '▲' : null))
                      : ''}
              </th>
              <th className="px-4 py-2 text-left cursor-pointer"
                onClick={() => handleSort('not_job_change_availability')}
              >
                転職可否✖ {sortConfig.key === 'not_job_change_availability' ?
                      (sortConfig.direction === 'desc' ?
                        '▼' : (sortConfig.direction==='asc' ? '▲' : null))
                      : ''}
              </th>
              <th className="px-4 py-2 text-left cursor-pointer"
                onClick={() => handleSort('not_interview_availability')}
              >
                面談可否✖ {sortConfig.key === 'not_interview_availability' ?
                      (sortConfig.direction === 'desc' ?
                        '▼' : (sortConfig.direction==='asc' ? '▲' : null))
                      : ''}
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredData
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((item, index) => (
              <tr key={index} className={`odd:bg-white even:bg-gray-100`}>
                <td className="px-4 py-2">{item.date}</td>
                <td className="px-4 py-2">{item.call_type || "-"}</td>
                <td className="px-4 py-2">{item.contact || "-"}</td>
                <td className="px-4 py-2">{item.rate_contact ? `${item.rate_contact}%` : "-"}</td>
                <td className="px-4 py-2">{item.interview_availability || "-"}</td>
                <td className="px-4 py-2">{item.rate_interview_outbound ? `${item.rate_interview_outbound}%` : "-"}</td>
                <td className="px-4 py-2">{item.rate_interview_contact ? `${item.rate_interview_contact}%` : "-"}</td>
                <td className="px-4 py-2">{item.phone_availability || "-"}</td>
                <td className="px-4 py-2">{item.not_phone_availability || "-"}</td>
                <td className="px-4 py-2">{item.job_change_availability || "-"}</td>
                <td className="px-4 py-2">{item.not_job_change_availability || "-"}</td>
                <td className="px-4 py-2">{item.not_interview_availability || "-"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Results per page"
        className=' right-16 bottom-5'
      />
    </div>
  );
};

export default DetailsTable;
