import React from 'react';
import { FaCloudDownloadAlt } from "react-icons/fa";
import { downloadCSV } from '../../utils/csvUtils.js';
const DownloadCSV = ({ data, btntext }) => {
    const handleDownload = () => {
        downloadCSV(data);
    };

    return (
        <button
            onClick={handleDownload}
            title="CSVダウンロード"
            className="flex items-center bg-white my-4 px-4 py-2 border-[1px] border-gray-300 rounded-md gap-2 max-h-11">
            <FaCloudDownloadAlt size={26} />
            <span className=' font-semibold'>{btntext}</span>
        </button>
    );
};

export default DownloadCSV;