import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';

const options = [
    '合計（架電数・受電数）',
    '架電数',
    '受電数'
];

const TypeCall = ({ onChange }) => {
    const [option, setOption] = React.useState('合計（架電数・受電数）');

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setOption(value);
        onChange(value);    // 親コンポーネントに通知
    };

    return (
        <div className=' flex flex-col items-start p-4'>
            <label htmlFor="callType" className="font-semibold mb-2">通話タイプ</label>
            <FormControl sx={{width: 300}}>
                <Select
                    value={option}
                    onChange={handleChange}
                    input={<OutlinedInput />}
                    renderValue={(selected) => (
                        <Typography sx={{ fontStyle: 'italic' }}>
                            {selected}
                        </Typography>
                    )}
                >
                    {options.map((option) => (
                        <MenuItem
                            key={option}
                            value={option}
                        >
                            {option}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}

export default TypeCall;
