import React from 'react';
import Header from '../../component/common/header';

export const metadata = {
  title: 'Unirobot-ai call',
  description: 'Clinic call management tool',
};
const RootLayout = ({ children }) => {

  return (
    <div className="min-h-screen img-bg bg-cover bg-center bg-fixed">
      <div className=" relative flex flex-col h-screen">
        <Header />
        {children}
      </div>
    </div>
  );
};

export default RootLayout;
