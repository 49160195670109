// Header.js
import React, { useContext } from 'react';
import { RiLayoutMasonryFill } from "react-icons/ri";
import { MdLogout } from "react-icons/md";
import { Link } from 'react-router-dom';
import { MdBarChart } from "react-icons/md";
import { FaArrowRotateRight } from "react-icons/fa6";
import { MdOutlineEdit } from "react-icons/md";
import { ImUsers } from "react-icons/im";
import { UserContext } from "../../context/UserContext";
import logo from '../../assets/images/service-logo.png';
import logoTop from '../../assets/images/service-logo-top.png';

const Header = () => {
  const { user, logout } = useContext(UserContext);
  const handleLogout = () => {
    logout();
  }

  const GuestHeader = () => (
    <header className="flex justify-between items-center p-4 xl:px-20 md:px-14 sm:px-10 px-10">
      <Link to='/login'>
        <img src={logo} alt="Unirobot-AI Call Logo" className="h-10" />
      </Link>
    </header>
  );

  const UserHeader = () => (
    <header className="flex justify-between items-center p-4 xl:px-20 md:px-14 sm:px-10 px-10 bg-[#2467C1] text-white">
      <div className="">
        <img src={logoTop} alt="Unirobot-AI Call Logo Top" className="h-10" />
      </div>
      <nav>
        <ul className="flex space-x-4">
          <li className="flex items-center space-x-1">
            <RiLayoutMasonryFill className="h-5 w-5" />
            <Link to="/dashboard" className="font-medium hover:underline">ダッシュボード</Link>
          </li>
          {user?.is_admin ? (
            <li className="flex items-center space-x-1">
              <ImUsers className="h-5 w-5" />
              <Link to="/dashboard/user-info" className="font-medium hover:underline">利用者情報</Link>
            </li>
          ) : null}
          <li className="flex items-center space-x-1">
            <MdOutlineEdit className="h-5 w-5" />
            <Link to="/dashboard/call-setting" className="font-medium hover:underline">通話設定</Link>
          </li>
          <li className="flex items-center space-x-1">
            <MdBarChart className="h-5 w-5" />
            <Link to="/dashboard/result" className="font-medium hover:underline">通話結果</Link>
          </li>
          <li className="flex items-center space-x-1">
            <FaArrowRotateRight className="h-5 w-5" />
            <Link to="/reset-password" className="font-medium hover:underline">パスワード変更</Link>
          </li>
          <li className="flex items-center space-x-1 cursor-pointer" onClick={handleLogout}>
            <MdLogout className="h-5 w-5" />
            <span className="font-medium hover:underline">ログアウト</span>
          </li>
        </ul>
      </nav>
    </header>
  );

  return (
    <>
      {user ? <UserHeader /> : <GuestHeader />}
    </>
  );
};

export default Header;
