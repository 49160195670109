import React, { useContext, useEffect, useState } from "react";
import Error from "../../component/notification/error";
import Notification from "../../component/notification/notification";
import { UserContext } from "../../context/UserContext";
import Header from "../../component/common/header";
import { MdOutlineVisibility } from "react-icons/md";
import { MdOutlineVisibilityOff } from "react-icons/md";
import { Container } from "@mui/material";

const Login = (props) => {

  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [content, setContent] = useState("");

  const { login } = useContext(UserContext);

  useEffect(() => {
    if(props.content) {
      setContent(props.content);
      props.SetNotification("");
    }
  }, [props.content]);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    props.setEmail(event.target.value)
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    login(email, password);
    // setContent('');
  };

  return (
    <div className="h-screen overflow-hidden">
      <Header />
      <Container className="h-full flex flex-col justify-center">
        <div className="h-full mx-16 px-16 pb-24">
          <div className="h-full max-w-[650px] flex flex-col items-center justify-center flex-grow mx-24 p-24">
            <h2 className="text-2xl font-bold text-center">ログイン</h2>
            <form className="w-full space-y-6 py-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  アカウントID（メールアドレス）<span className="text-red-500"> 必須</span>
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                  required
                  className="w-full px-3 py-3 mt-1 border-2 border-gray-500 rounded-lg focus:outline-none  focus:border-blue-300"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  パスワード<span className="text-red-500"> 必須</span>
                </label>
                <div style={{ width: '100%', position: 'relative' }}>
                  <input
                    id="password"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={handlePasswordChange}
                    required
                    className="w-full px-3 py-3 mt-1 border-2 border-gray-500 rounded-lg focus:outline-none  focus:border-blue-300"
                  />
                  <button
                    type="button"
                    onClick={handleTogglePassword}
                    style={{
                      position: 'absolute',
                      right: '10px',
                      top: '50%',
                      transform: 'translateY(-50%)',
                      background: 'none',
                      border: 'none',
                      cursor: 'pointer',
                    }}
                  >
                    {showPassword ? <MdOutlineVisibility /> : <MdOutlineVisibilityOff />}
                  </button>
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  className="w-full py-3 text-white bg-[#2467C1] rounded-full hover:bg-blue-800"
                  onClick={handleSubmit}
                >
                  ログイン
                </button>
              </div>
            </form>
            <div className="text-center">
              <a href="/forget-password" className="text-sm text-blue-500 underline">
                パスワードを忘れた方はこちら
              </a>
              <br />
              <a href="/register" className="text-sm text-blue-500 underline">
                アカウントを持っていない方はこちら
              </a>
            </div>
          </div>
        </div>
        <Error content={error} />
        <Notification content={content} />
      </Container>
    </div>
  );
};

export default Login;
