import axios from 'axios';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const ChartCalls = ({ selected, startDate, endDate, typeCall }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [categories, setCategories] = useState([]);
  const [seriesData, setSeriesData] = useState([]);
  const [chartTitle, setChartTitle] = useState('架電数・受電数');
  const [error, setError] = useState(""); // エラーハンドリング用
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('token');

    const params = {};
    if(selected) params['user_id'] = selected;
    if(isInitialLoad) { // ログイン直後
      if(startDate && endDate) {
        params['start_date'] = startDate.format('YYYY-MM-DD');
        params['end_date'] = endDate.format('YYYY-MM-DD');
        setIsInitialLoad(false); // フラグをオフにする
      }
    }else {
      if(startDate) params['start_date'] = dayjs(startDate).format('YYYY-MM-DD');
      if(endDate) params['end_date'] = dayjs(endDate).format('YYYY-MM-DD');
    }
    if(typeCall) params['call_type'] = typeCall;

    axios.get(`${apiUrl}/api/call_result/getOutbound/`, {
      headers: { Authorization: `Bearer ${token}` },
      params: params
    })
    .then(response => {
      const responseData = response.data;
      
      // 日付と値を分けて配列に変換
      const dates = responseData.map(item => item.date);
      const values = responseData.map(item => item.call_count);
      
      setCategories(dates);
      setSeriesData(values);
      if(typeCall=='架電数') {
        setChartTitle('架電数');
      }else if(typeCall == '受電数') {
        setChartTitle('受電数');
      }else {
        setChartTitle('架電数・受電数');
      }
    })
    .catch((error) => {
      console.log("Error: ", error);
      localStorage.removeItem('token');
      setError("架電数情報の取得に失敗しました。")
    })
  }, [selected, startDate, endDate, typeCall]);

  const series = [
    {
      name: chartTitle,
      data: seriesData, // APIから取得したデータ
    },
  ];

  const options = {
    chart: {
      type: 'bar',
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '50%',
        endingShape: 'rounded',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: categories  // APIから取得した日付
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: (val) => `${val} 件`,
      },
    },
    title: {
      text: chartTitle,
      align: 'center',
      style: {
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#000',
      },
    },
  };

  return (
    <div className="bg-white w-full rounded-2xl p-5 border-[1px] overflow-hidden">
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={350}
      />
    </div>
  );
};

export default ChartCalls;
